import React, { useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  TextField,
} from "@mui/material";
import { PageHeader, PageLayout, PageMain } from "components/layout/PageLayout";
import {
  requiredFieldsForCreation,
  usePollById,
  usePollUpdate,
} from "../hooks";
import { useContent } from "hooks/useContent";
import dayjs from "dayjs";
import { PUBLISHED } from "constants/content";
import { Callout } from "components/Callout";
import PollsBrands from "./pollBrands";

export const Poll = () => {
  const { pollId: id } = useParams();
  const navigate = useNavigate();
  const [pollId] = useState(() => (id === "new" ? undefined : id));
  const [changes, setChanges] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorField, setErrorField] = useState("");
  const [articleSearch, setArticleSearch] = React.useState("");
  const { data: _articles } = useContent({
    search: articleSearch,
  });
  const articles = useMemo(() => {
    return (
      _articles?.content?.filter((article) => article.status === PUBLISHED) ||
      []
    );
  }, [_articles]);
  const { data, refetch, isLoading } = usePollById(pollId);
  const { mutateAsync: updatePoll } = usePollUpdate(pollId);
  const poll = useMemo(
    () =>
      data?.poll ?? {
        question: undefined,
        pollKey: undefined,
        startDate: undefined,
        endDate: undefined,
        options: [],
        insightImageUrl: undefined,
        insightText: undefined,
        insightSubtext: undefined,
        articleIntroText: undefined,
        articleSlugs: [],
      },
    [data]
  );
  const isEditable = useMemo(() => {
    const endDate = poll?.endDate;
    if (!endDate) {
      return true;
    } else if (dayjs(endDate).isBefore(dayjs())) {
      return false;
    } else {
      return true;
    }
  }, [poll]);

  const hasChanges = useMemo(() => {
    const changeKeys = Object.keys(changes);
    return (
      changeKeys.length > 0 &&
      changeKeys.some((key) => changes[key] !== poll[key])
    );
  }, [changes, poll]);
  const isPollUpdating = false;
  const clearChanges = () => setChanges({});

  const handleChanges = (e) => {
    setChanges({
      ...changes,
      [e.target.name]: e.target.value,
    });
  };

  const isCreation = !pollId;
  const canSaveCreation = requiredFieldsForCreation.every(
    (field) => changes[field]
  );

  if (isLoading && pollId) {
    return <CircularProgress />;
  }

  return (
    <>
      <PageLayout>
        <PageHeader>
          <>
            <Box minWidth="200px">
              {hasChanges && (
                <Box m={0} as="small">
                  (Unsaved changes)
                </Box>
              )}
              <Box m={0} as="h4">
                {!pollId ? "Creating New Poll" : "Editing Poll:"}{" "}
                {changes?.pollKey ?? poll?.pollKey}
              </Box>
            </Box>
            <Box ml={2}>
              <Button
                variant="contained"
                disabled={
                  !hasChanges ||
                  isPollUpdating ||
                  (isCreation && !canSaveCreation)
                }
                onClick={async () => {
                  if (hasChanges) {
                    const startDate = changes?.startDate ?? poll?.startDate;
                    const endDate = changes?.endDate ?? poll?.endDate;
                    if (startDate && dayjs(endDate).isSameOrBefore(startDate)) {
                      setErrorMessage("End date cannot be before start date");
                      setErrorField("endDate");
                      return;
                    } else {
                      try {
                        const resp = await updatePoll({
                          ...changes,
                        });
                        if (resp?.poll?.id && !pollId) {
                          navigate(`/polls/${resp.poll.id}`);
                          return;
                        }

                        setChanges({});
                        setErrorMessage(null);
                        setErrorField("");
                        await refetch();
                      } catch (e) {
                        if (
                          e ==
                          "Error: Validation error: Validation isIn on pollKey failed"
                        ) {
                          setErrorMessage(
                            "Matching pollkey does not exist in the codebase yet. Ping a dev."
                          );
                          setErrorField("pollKey");
                        } else {
                          setErrorMessage(e.message);
                        }
                      }
                    }
                  }
                }}
                size="small"
              >
                Save
              </Button>
            </Box>
            <Box ml={2}>
              <Button
                variant="contained"
                disabled={!hasChanges || isPollUpdating}
                onClick={clearChanges}
                size="small"
              >
                Cancel
              </Button>
            </Box>
            {isPollUpdating && <CircularProgress />}
          </>
        </PageHeader>
        <PageMain>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              width: "40vw",
              mb: 2,
            }}
          >
            {errorMessage ? (
              <Callout type="warn">{errorMessage}</Callout>
            ) : !isEditable ? (
              <Callout type="info">Poll has ended</Callout>
            ) : isNaN(pollId) ? (
              <Callout type="info">Save poll first to select brands</Callout>
            ) : null}
            <TextField
              label="Poll key (must be added by a dev to the codebase first)"
              name="pollKey"
              value={changes?.pollKey ?? poll?.pollKey}
              onChange={handleChanges}
              error={errorField === "pollkey"}
              onInput={() => {
                setErrorField("");
                setErrorMessage(null);
              }}
              placeholder="Example: pollTypicalSecondhandShoppingSource"
              InputProps={{
                readOnly: !!poll?.pollKey || !isEditable,
                disabled: !!poll?.pollKey || !isEditable,
              }}
              sx={{
                width: "100%",
                mb: 2,
              }}
            />
            <Box
              display="flex"
              flexDirection="row"
              sx={{
                mb: 2,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField
                  label="Poll Start Date (must be a Monday)"
                  value={dayjs(changes?.startDate ?? poll?.startDate)}
                  format="LL"
                  sx={{
                    pr: 2,
                  }}
                  InputProps={{
                    readOnly: !isEditable,
                    disabled: !isEditable,
                  }}
                  onChange={(e) =>
                    setChanges({
                      ...changes,
                      startDate: e
                        .hour(9)
                        .minute(0)
                        .second(0)
                        .millisecond(0)
                        .toDate(),
                    })
                  }
                />
                <DateField
                  label="Poll End Date (must be a Friday)"
                  value={dayjs(changes?.endDate ?? poll?.endDate)}
                  format="LL"
                  InputProps={{
                    readOnly: !isEditable,
                    disabled: !isEditable,
                  }}
                  error={errorField === "endDate"}
                  onChange={(e) => {
                    setErrorField("");
                    setErrorMessage(null);
                    setChanges({
                      ...changes,
                      endDate: e
                        .hour(9)
                        .minute(0)
                        .second(0)
                        .millisecond(0)
                        .toDate(),
                    });
                  }}
                />
              </LocalizationProvider>
            </Box>
            <TextField
              label="Poll Question"
              name="question"
              placeholder="Example: How are you getting your Christmas tree this year?"
              value={changes?.question ?? poll?.question}
              onChange={handleChanges}
              sx={{ mb: 2 }}
              InputProps={{
                readOnly: !isEditable,
                disabled: !isEditable,
              }}
            />
            <Autocomplete
              multiple
              name="options"
              freeSolo
              value={changes?.options ?? poll?.options}
              options={[]}
              disableClearable
              onChange={(e, value, reason, details) => {
                let options = changes?.options ?? poll?.options;
                if (reason === "createOption") {
                  options.push(details.option);
                } else {
                  options = options?.filter((opt) => opt !== details.option);
                }
                setChanges({
                  ...changes,
                  options: options,
                });
              }}
              InputProps={{
                readOnly: !isEditable,
                disabled: !isEditable,
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <Chip
                      variant="outlined"
                      label={option}
                      key={key}
                      {...tagProps}
                      disabled={!isEditable}
                      onDelete={() => {
                        setChanges({
                          ...changes,
                          options: value.filter(
                            (_option) => _option !== option
                          ),
                        });
                      }}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Poll Answer Options"
                  sx={{ mb: 4 }}
                />
              )}
            />

            {poll?.insightImageUrl ||
            poll?.insightText ||
            poll?.insightSubtext ? (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  sx={{
                    mb: 2,
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                      width: "20vw",
                    }}
                  >
                    <TextField
                      label="Insight Image URL - DEPRECATED"
                      name="insightImageUrl"
                      value={poll?.insightImageUrl}
                      placeholder="Example: https://joro-assets.s3.amazonaws.com/potato-upload/-1-trend-poll.jpg"
                      sx={{ mb: 2 }}
                      InputProps={{
                        readOnly: true,
                        disabled: true,
                      }}
                    />
                    <TextField
                      label="Insight Text - DEPRECATED"
                      name="insightText"
                      value={poll?.insightText}
                      placeholder="Example: In 2022, Commons users saved $200 a month by spending more sustainably."
                      sx={{ mb: 2 }}
                      InputProps={{
                        readOnly: true,
                        disabled: true,
                      }}
                    />
                    <TextField
                      label="Insight Subtext - DEPRECATED"
                      name="insightSubtext"
                      value={poll?.insightSubtext}
                      placeholder="Example: Source: Textile Exchange, 2021"
                      sx={{ mb: 2 }}
                      InputProps={{
                        readOnly: true,
                        disabled: true,
                      }}
                    />
                  </Box>
                  <Box
                    as="img"
                    src={changes?.insightImageUrl ?? poll?.insightImageUrl}
                    alt={"insight image"}
                    style={{
                      width: "8vw",
                      height: "8vw",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              ) : null}

            <TextField
              label="Article Carousel Intro Text"
              name="articleIntroText"
              value={changes?.articleIntroText ?? poll?.articleIntroText}
              onChange={handleChanges}
              sx={{ mb: 2 }}
              placeholder="Example: Learn more about sustainable shopping"
              InputProps={{
                readOnly: !isEditable,
                disabled: !isEditable,
              }}
            />
            <Autocomplete
              multiple
              sx={{ mb: 4 }}
              id="articles"
              inputValue={articleSearch}
              onInputChange={(event, newInputValue) => {
                setArticleSearch(newInputValue);
              }}
              options={articles}
              value={
                changes?.articleSlugs
                  ? articles?.filter((content) =>
                    changes?.articleSlugs?.includes(content.slug)
                  )
                  : poll?.articleSlugs
                    ? articles?.filter((content) =>
                      poll?.articleSlugs?.includes(content.slug)
                    )
                    : []
              }
              disableClearable
              onChange={(e, value, reason, details) => {
                let articleSlugs = changes?.articleSlugs ?? poll?.articleSlugs;
                if (reason === "selectOption") {
                  articleSlugs.push(details.option.slug);
                } else {
                  articleSlugs = articleSlugs?.filter(
                    (slug) => slug !== details.option.slug
                  );
                }
                setChanges({
                  ...changes,
                  articleSlugs: articleSlugs,
                });
              }}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Articles to Display"
                />
              )}
            />
            <TextField
              label="Brand Carousel Intro Text"
              name="brandsIntroText"
              value={changes?.brandsIntroText ?? poll?.brandsIntroText}
              onChange={handleChanges}
              sx={{ mb: 2 }}
              placeholder="Example: Alternatives to fast fashion"
              InputProps={{
                readOnly: !isEditable,
                disabled: !isEditable,
              }}
            />
            {!isNaN(pollId) ? <PollsBrands pollId={parseInt(pollId)} /> : null}
          </Box>
        </PageMain>
      </PageLayout>
    </>
  );
};
