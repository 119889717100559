import React, { useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TurndownService from "turndown";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  PageHeader,
  PageLayout,
  PageMain,
  PageRightSidebar,
} from "components/layout/PageLayout";
import { useContentById, useContentUpdate } from "hooks/useContent";
import { ImagePreviewInput } from "components/ImagePreviewInput";
import { SidebarModule } from "components/Vendor/SidebarModule";
import { ContentPreview } from "./ContentPreview";
import { COLORS } from "constants/colors";
import { ContentTagDisplayNames, ContentTags } from "./constants";
import moment from "moment";
import { useWebflowBlogs, useWebflowBlogById } from "hooks/useWebflow";
import { useBrandsQuery } from "components/Genie/ProductTypes/hooks";

const contentTags = Object.values(ContentTags)
  .sort()
  .map((tag) => ({
    value: tag,
    label: ContentTagDisplayNames[tag],
  }));

const requiredFieldsForCreation = [
  "title",
  "body",
  "coverImageURL",
  "previewText",
  "coverImageURL",
  "slug",
];

/**
 * Translate HTML to markdown
 * @param {*} html
 * @param {*} brands
 * @returns
 */
const translateBody = (html, brands) => {
  const turndownService = new TurndownService();
  turndownService.keep("script");
  turndownService.addRule("removeEmptyParagraphs", {
    filter: "p",
    replacement: function (content) {
      return content.trim() === "" ? "" : `\n${content}\n`;
    },
  });
  turndownService.addRule("removeEmptySpans", {
    filter: "span",
    replacement: function (content) {
      return content.trim() === "" ? "" : content;
    },
  });
  turndownService.addRule("removeEmptyDivs", {
    filter: "div",
    replacement: function (content) {
      return content.trim() === "" ? "" : content;
    },
  });
  // get the data-brand tags, find the correct vendor ID, and replace with @brand
  turndownService.addRule("replaceBrandEmbeds", {
    filter: "script",
    replacement: function (content, node) {
      const brandId = node.getAttribute("data-brand");
      if (brandId) {
        let updatedBrandId = brandId;
        if (brands) {
          const brand = brands.find((b) => b.id === parseInt(brandId, 10));
          if (brand) {
            updatedBrandId = brand.Vendor?.id;
          }
        }
        return `@brand ${updatedBrandId}`;
      } else {
        return "";
      }
    },
  });
  return turndownService.turndown(html);
};


export const Content = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [contentId] = useState(() => (id === "new" ? undefined : id));
  const [previewOpen, setPreviewOpen] = useState(false);
  const [changes, setChanges] = useState({});
  const [selectedWebflowBlog, setSelectedWebflowBlog] = useState(null);

  const { data, refetch, isLoading } = useContentById(contentId);
  const { mutateAsync: updateContent } = useContentUpdate(contentId);
  // brands used by importer to translate brands to vendors
  const { data: allBrands, isLoading: allBrandsLoading, } = useBrandsQuery();
  const content = useMemo(() => data?.content ?? {}, [data]);
  const copyWebflowToContent = (webflowBlog) => {
    if (webflowBlog) {
      setChanges((prevChanges) => ({
        ...prevChanges,
        title: webflowBlog.title,
        body: translateBody(webflowBlog.body, allBrands.brands),
        coverImageURL: webflowBlog.coverImageURL,
        author: webflowBlog.author,
        slug: webflowBlog.slug,
        previewText: webflowBlog.previewText,
      }));
    }
  };

  const { data: webflowBlogsData, isLoading: isWebflowBlogsLoading } = useWebflowBlogs();
  const { data: webflowBlogData } = useWebflowBlogById(selectedWebflowBlog?.id);
  const webflowBlogs = useMemo(() => webflowBlogsData?.blogs ?? [], [webflowBlogsData]);

  const hasChanges = useMemo(() => {
    const changeKeys = Object.keys(changes);
    return (
      changeKeys.length > 0 &&
      changeKeys.some((key) => changes[key] !== content[key])
    );
  }, [changes, content]);
  const isContentUpdating = false;
  const clearChanges = () => setChanges({});

  const handleChanges = (e) => {
    setChanges({
      ...changes,
      [e.target.name]: e.target.value,
    });
  };

  const isCreation = !contentId;
  const canSaveCreation = requiredFieldsForCreation.every(
    (field) => changes[field]
  );

  if ((isLoading && contentId) || allBrandsLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <PageLayout>
        <PageHeader>
          <>
            <Box minWidth="200px">
              {hasChanges && (
                <Box m={0} as="small">
                  (Unsaved changes)
                </Box>
              )}
              <Box m={0} as="h4">
                {!contentId ? "Creating" : "Editing"}{" "}
                {changes?.title ?? content?.title}
              </Box>
            </Box>
            <Box ml={2}>
              <Button
                variant="contained"
                disabled={
                  !hasChanges ||
                  isContentUpdating ||
                  (isCreation && !canSaveCreation)
                }
                onClick={async () => {
                  if (hasChanges) {
                    const resp = await updateContent({
                      ...changes,
                    });
                    if (resp?.content?.id && !contentId) {
                      navigate(`/articles/${resp.content.id}`);
                      return;
                    }

                    setChanges({});
                    await refetch();
                  }
                }}
                size="small"
              >
                Save
              </Button>
            </Box>
            <Box ml={2}>
              <Button
                variant="contained"
                disabled={!hasChanges || isContentUpdating}
                onClick={clearChanges}
                size="small"
              >
                Cancel
              </Button>
            </Box>
            {isContentUpdating && <CircularProgress />}
          </>
        </PageHeader>
        <PageMain>
          <ImagePreviewInput
            alt={changes?.title ?? content?.title}
            name="coverImageURL"
            src={changes?.coverImageURL ?? content?.coverImageURL}
            onSrcChange={(e) => {
              setChanges((c) => ({
                ...c,
                coverImageURL: e.target.value,
              }));
            }}
            sx={{
              width: "40vw",
              mb: 2,
            }}
          />
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              width: "40vw",
              mb: 2,
            }}
          >
            {content?.publishedAt && (
              <Box m={0} pb={2} as="small">
                Published on{" "}
                {moment(content.publishedAt).format("MMM D YYYY, h:mm a")}
              </Box>
            )}
            <TextField
              label="Title"
              name="title"
              value={changes?.title ?? content?.title ?? ""}
              onChange={(e) => {
                setChanges({
                  ...changes,
                  title: e.target.value,
                });
              }}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Author"
              name="author"
              value={changes?.author ?? content?.author ?? ""}
              onChange={handleChanges}
              sx={{ mb: 2 }}
            />
            <TextField
              multiline
              name="body"
              value={changes?.body ?? content?.body ?? ""}
              onChange={(e) => {
                setChanges({
                  ...changes,
                  body: e.target.value,
                });
              }}
              sx={{ mb: 2 }}
            />
          </Box>
        </PageMain>
        <PageRightSidebar>
          <SidebarModule title="Publish" isExpanded>
            <Select
              id="status"
              value={changes?.status ?? content?.status}
              sx={{ width: "100%", mb: 2 }}
            >
              <MenuItem
                onClick={() => {
                  setChanges({
                    ...changes,
                    status: "PUBLISHED",
                  });
                }}
                value="PUBLISHED"
              >
                Published
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setChanges({
                    ...changes,
                    status: "DRAFT",
                  });
                }}
                value="DRAFT"
              >
                Draft
              </MenuItem>
            </Select>
            <Button
              variant="contained"
              onClick={() => {
                setPreviewOpen(!previewOpen);
              }}
            >
              PREVIEW
            </Button>
          </SidebarModule>

          <SidebarModule title="Meta" isExpanded>
            <TextField
              label="Slug"
              name="slug"
              value={changes?.slug ?? content?.slug ?? ""}
              onChange={handleChanges}
              sx={{
                width: "100%",
                mb: 2,
              }}
            />
            <TextField
              label="Preview Text"
              name="previewText"
              multiline
              value={changes?.previewText ?? content?.previewText ?? ""}
              onChange={handleChanges}
              sx={{
                width: "100%",
                mb: 2,
              }}
            />
            <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
            <Select
              id="tags"
              multiple
              value={(changes.tags ?? content.tags ?? []).map(
                (tag) => contentTags.find((t) => t.value === tag)?.label
              )}
              sx={{ mb: 2 }}
              onChange={(e) => {
                const mappedTags = e.target.value
                  .map((tag) => contentTags.find((t) => t.label === tag).value)
                  .filter((x) => !!x);

                setChanges({
                  ...changes,
                  tags: mappedTags,
                });
              }}
              input={
                <OutlinedInput
                  label="Tags"
                  sx={{
                    width: "100%",
                    maxWidth: "300px",
                  }}
                />
              }
              renderValue={(selected) => {
                return selected.join(", ");
              }}
            >
              {contentTags.map(({ label, value }) => (
                <MenuItem key={label} value={label}>
                  <Checkbox
                    checked={(changes.tags ?? content?.tags ?? []).includes(
                      value
                    )}
                  />
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>

            <TextField
              label="Search Terms"
              name="searchTerms"
              value={changes?.searchTerms ?? content?.searchTerms ?? ""}
              onChange={handleChanges}
            />
          </SidebarModule>
          <SidebarModule title="Tools" isExpanded>
            <Box>
              <InputLabel id="webflow-articles">Webflow Articles</InputLabel>
              <Autocomplete
                id="webflow-articles"
                options={webflowBlogs}
                getOptionLabel={(option) => option.title}
                loading={isWebflowBlogsLoading}
                onChange={(_e, value) => {
                  setSelectedWebflowBlog(value);
                }}
                sx={{ width: "100%", mb: 2 }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
            <Button
              variant="contained"
              onClick={() => {
                copyWebflowToContent(webflowBlogData?.blog);
              }}
              disabled={!webflowBlogData}
            >
              COPY
            </Button>
            {
              selectedWebflowBlog && (
                <a
                  href={`https://thecommons.earth/blog/${webflowBlogData?.blog?.slug}`}
                  target="_blank"
                  rel="noreferrer"
                >
              See on Webflow
                </a>)
            }
          </SidebarModule>
        </PageRightSidebar>
      </PageLayout>
      <Modal
        open={previewOpen}
        onClose={() => {
          setPreviewOpen(false);
        }}
      >
        <Box
          height="100vh"
          width="100vw"
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            setPreviewOpen(false);
          }}
        >
          <Card
            onClick={(e) => {
              e.stopPropagation();
            }}
            sx={{ borderRadius: 4 }}
          >
            <Box
              maxWidth="min(80vw,600px)"
              maxHeight="80vh"
              position="relative"
              sx={{
                overflowY: "scroll",
              }}
            >
              <Box
                sx={{
                  position: "sticky",
                  top: 0,
                  background: COLORS.PERIWINKLE,
                }}
                display="flex"
                justifyContent="space-between"
                padding={2}
              >
                <Typography variant="h4" fontWeight="bold" color={COLORS.WHITE}>
                  Content Preview
                </Typography>
              </Box>
              <Box padding={6}>
                <ContentPreview
                  title={changes?.title ?? content?.title}
                  author={changes?.author ?? content?.author}
                  imageURL={changes?.coverImageURL ?? content?.coverImageURL}
                  body={changes?.body ?? content?.body}
                />
              </Box>
            </Box>
          </Card>
        </Box>
      </Modal>
    </>
  );
};
