import { useMutation, useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../components/utils";

export const usePolls = () =>
  useQuery(["polls"], () =>
    makeApiRequest({
      endpoint: "/api/polls",
      method: "GET",
      json: true,
    })
  );

export const usePollById = (pollId) => {
  return useQuery(["polls", pollId], () => {
    return makeApiRequest({
      endpoint: `/api/polls/${pollId}`,
      method: "GET",
      json: true,
      additionalHeaders: {
        "Content-Type": "application/json",
      },
    });
  }, {
    enabled: !!pollId
  });
};

export const usePollUpdate = (id) => {
  return useMutation((body) => {
    return makeApiRequest({
      endpoint: `/api/polls${id ? `/${id}` : ""}`,
      method: "POST",
      json: true,
      body,
      additionalHeaders: {
        "Content-Type": "application/json",
      },
    });
  });
};

export const requiredFieldsForCreation = [
  "question",
  "options",
  "pollKey",
  "startDate",
  "endDate",
  "articleIntroText",
  "articleSlugs",
];
