import React, { useMemo, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Box,
  Button,
  List,
  Modal,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { COLORS } from "constants/colors";
import { usePollBrands, usePollBrandsMutation } from "hooks/usePollBrands";
import { useBrandsQuery } from "components/Genie/ProductTypes/hooks";

const scoreToStars = (score, primarySubcategoryId) => {
  if (primarySubcategoryId === 249) {
    return "Secondhand";
  }
  if (!score) {
    return "Not Rated";
  }
  const stars = Math.round(score);
  return "★".repeat(stars) + "☆".repeat(5 - stars);
};

function PollBrandCard({ vendorName, id, score, onDelete, primarySubcategoryId }) {
  return (
    <Box
      key={id}
      sx={{
        mb: 1,
        p: 2,
        borderRadius: 2,
        boxShadow: `inset 0px 0px 0px 1px ${COLORS.OFFSET}`,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">{vendorName}</Typography>
        <Button onClick={() => onDelete(id)} startIcon={<DeleteIcon />} />
      </Box>

      <Typography variant="body2">{scoreToStars(score, primarySubcategoryId)}</Typography>
    </Box>
  );
}

function BrandOption({ brand }) {
  const primarySubcategoryId = brand?.Vendor?.JoroSubcategoriesVendors?.find((subcatVendor) => subcatVendor.isPrimary)?.joroSubcategoryId;
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: "100%" }}
    >
      <Typography sx={{ flex: 1 }}>{brand?.Vendor?.vendorName}</Typography>
      <Typography sx={{ flex: 1, textAlign: "right" }}>
        {scoreToStars(brand?.rubricScore?.score, primarySubcategoryId)}
      </Typography>
    </Box>
  );
}
const PollBrands = ({ pollId }) => {

  const {
    isLoading: allBrandsLoading,
    error: allBrandsError,
    data: allBrands,
  } = useBrandsQuery();
  const {
    isLoading: pollBrandsLoading,
    error: pollBrandsError,
    data: pollBrands,
  } = usePollBrands({ pollId });
  const { create, deleteRecord } = usePollBrandsMutation({ pollId });
  const loading = allBrandsLoading || pollBrandsLoading;
  const error = allBrandsError || pollBrandsError;
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const pollBrandIds = useMemo(() => {
    return pollBrands?.pollBrands?.map((pollBrand) => pollBrand?.brandId);
  }, [pollBrands]);

  const brandOptions = useMemo(() => {
    // Don't display existing pollBrands as an option
    if (pollBrandIds?.length > 0) {
      return allBrands?.brands?.filter((brand) => !pollBrandIds?.includes(brand.id));
    } else {
      return allBrands?.brands;
    }

  }, [allBrands, pollBrandIds]);

  const onSelectBrand = async (brandId) => {
    await create.mutateAsync({ brandId: brandId, pollId });
  };

  const onDelete = async (id) => {
    setDeleteId(id);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = async () => {
    await deleteRecord.mutateAsync({ pollBrandId: deleteId });
    setOpenConfirmDialog(false);
    setDeleteId(null);
  };

  return (
    <>
      {loading ? (
        <Skeleton variant="rectangular" width="300px" height={400} />
      ) : (
        <>
          <Autocomplete
            sx={{ mb: 2 }}
            options={brandOptions}
            getOptionLabel={(option) => option?.Vendor?.vendorName ?? "Select a Brand"}
            style={{ width: 300 }}
            clearOnBlur={true}
            clearOnEscape={true}
            value={value}
            inputValue={inputValue}
            onInputChange={(_event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={async (_event, value) => {
              if (value) {
                await onSelectBrand(value.id);
              }
              setInputValue("");
              setValue(null);
            }}
            renderOption={(props, option) => (
              <li {...props}>
                <BrandOption brand={option} />
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Brands to Display" variant="outlined" />
            )}
          />
          {error && (
            <Typography variant="body1" color="error">
              {error.message}
            </Typography>
          )}
          <List>
            {pollBrands?.pollBrands?.map((pollBrand, idx) => (
              <PollBrandCard
                key={pollBrand.id}
                id={pollBrand?.id}
                vendorName={pollBrand?.Brand?.Vendor?.vendorName}
                onDelete={onDelete}
                score={pollBrand?.Brand?.rubricScore?.score}
                primarySubcategoryId={pollBrand?.Brand?.Vendor?.JoroSubcategoriesVendors?.find((subcatVendor) => subcatVendor.isPrimary)?.joroSubcategoryId}
                idx={idx}
              />
            ))}
          </List>
        </>
      )}
      <Modal open={openConfirmDialog}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            Are you sure you want to delete this brand?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              onClick={() => {
                setOpenConfirmDialog(false);
              }}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={handleConfirmDelete}>
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default PollBrands;
