import { RubricsChecks } from "./constants";

const escapeRegex = (string) => {
  // Escape special characters in a regex string
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const addWordBoundaries = (terms) => {
  // Add word boundaries to individual words, but avoid applying them to multi-word terms
  return terms
    .split("|")
    .map((term) =>
      term.includes(" ") ? escapeRegex(term) : `\\b${escapeRegex(term)}\\b`
    )
    .join("|");
};

const checkForTextMatch = ({
  textToCheck,
  termsCaseSensitive,
  termsCaseInsensitive,
}) => {
  const caseInsensitiveRegex = new RegExp(addWordBoundaries(termsCaseInsensitive), "i");
  const caseInsensitiveMatches = textToCheck.match(caseInsensitiveRegex);

  if (termsCaseSensitive) {
    const caseSensitiveRegex = new RegExp(addWordBoundaries(termsCaseSensitive));
    const caseSensitiveMatches = textToCheck.match(caseSensitiveRegex);
    return caseInsensitiveMatches?.length > 0 || caseSensitiveMatches?.length > 0;
  } else {
    return caseInsensitiveMatches?.length > 0;
  }
};

export const getTextMatchResults = ({ selectedRubric, textToCheck }) => {
  if (!selectedRubric) {
    return null;
  }
  let results = [];
  for (const check of RubricsChecks[selectedRubric]) {
    results.push({
      id: check.id,
      question: check.question,
      answer: checkForTextMatch({
        textToCheck,
        termsCaseSensitive: check.termsCaseSensitive,
        termsCaseInsensitive: check.termsCaseInsensitive,
      }),
    });
  }
  return results;
};
