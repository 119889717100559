import React, { useCallback } from "react";
import {
  Outlet,
  useSearchParams,
  useNavigate,
  useParams,
} from "react-router-dom";
import Box from "@mui/material/Box";
import { Button, ButtonGroup, Container, debounce } from "@mui/material";
import { SearchSidebar } from "../layout/SearchSidebar";
import { SearchResultItem } from "../layout/SearchResultItem";
import { useContent } from "../../hooks/useContent";
import { resolveUrl } from "components/ImageUploader/utils";

export function ContentSearch() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const search = searchParams.get("search") ?? "";
  const { data } = useContent({
    search,
    limit: 100,
  });
  const statusFilter = searchParams.get("status")?.toUpperCase();

  const content = (data?.content ?? []).filter((thisContent) =>
    statusFilter ? thisContent.status === statusFilter : true
  );

  const setFilter = (filter) => {
    setSearchParams(
      {
        search,
        ...filter,
      },
      { replace: true }
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(debounce(setSearchParams, 500), [
    setSearchParams,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        width: "100%",
      }}
    >
      <SearchSidebar
        defaultSearch={search}
        placeholder="Search Content"
        onChange={(event) => {
          onSearch(
            {
              search: event.target.value,
            },
            { replace: true }
          );
        }}
        asideContent={
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              navigate(`/articles/new?${searchParams.toString()}`);
            }}
          >
            +
          </Button>
        }
        belowContent={
          <ButtonGroup
            size="small"
            sx={{
              display: "flex",
              flex: 1,
              button: { flexGrow: 1 },
            }}
          >
            <Button
              variant={
                searchParams.get("status") === "published"
                  ? "contained"
                  : "outlined"
              }
              onClick={() => {
                setFilter({ status: "published" });
              }}
              flex={1}
            >
              Published
            </Button>
            <Button
              variant={
                searchParams.get("status") === "draft"
                  ? "contained"
                  : "outlined"
              }
              onClick={() => {
                setFilter({ status: "draft" });
              }}
            >
              Draft
            </Button>
          </ButtonGroup>
        }
      >
        {content.map((thisContent) => (
          <SearchResultItem
            key={thisContent.id}
            title={thisContent.title}
            subtitle={"/" + thisContent.slug ?? "N/A"}
            imageURL={resolveUrl(thisContent.coverImageURL, {
              width: 100,
              height: 100,
              fit: "crop",
            })}
            square
            objectFit="cover"
            href={`/articles/${thisContent.id}?${searchParams.toString()}`}
          />
        ))}
      </SearchSidebar>
      <Container>
        <Outlet key={id} />
      </Container>
    </Box>
  );
}
