import React from "react";
import { createBrowserRouter } from "react-router-dom";

import Root from "../react-routes/root";
import { Vendors, Vendor } from "../components/Vendor";
import Transaction, {
  loader as transactionLoader,
} from "../components/Transaction";
import Proposals from "../components/Proposals";
import { Welcome } from "../components/Welcome";
import { ErrorBoundary } from "../components/ErrorBoundary";
import { Carbonizer } from "../components/Carbonizer/Carbonizer";
import { PageLayout } from "components/Page";
import { ImageUploader } from "components/ImageUploader";
import { ContentSearch } from "../components/Content/ContentSearch";
import { Content } from "../components/Content/Content";
import { Genie } from "../components/Genie";
import ProductTypes from "../components/Genie/ProductTypes";
import { Poll, PollsList } from "../components/Polls";
import { IngredientChecker } from "../components/Genie/IngredientChecker";
import { BrandEmail } from "components/CSVUploaders";
export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "vendors",
        element: <Vendors />,
        children: [
          {
            path: ":id",
            element: <Vendor />,
          },
        ],
      },
      {
        path: "articles",
        element: <ContentSearch />,
        children: [
          {
            path: ":id",
            element: <Content />,
          },
        ],
      },
      {
        path: "new/transactions/:id/edit",
        element: <Transaction />,
        loader: transactionLoader,
      },
      {
        path: "proposals",
        element: <Proposals />,
      },
      {
        path: "genie",
        element: <Genie />,
      },
      {
        path: "genie/producttypes",
        element: <ProductTypes />,
      },
      {
        path: "polls",
        element: <PollsList />,
        children: [
          {
            path: ":pollId",
            element: <Poll />,
          },
        ],
      },
      {
        path: "uploaders",
        element: <BrandEmail />,
      },
      {
        path: "carbon",
        element: <Carbonizer />,
      },
      {
        path: "images",
        element: <ImageUploader />,
      },
      {
        path: "checker",
        element: <IngredientChecker />,
      },
      {
        index: true,
        element: <Welcome />,
      },
      {
        path: "*",
        element: (
          <PageLayout>
            <h1>404 not found</h1>
          </PageLayout>
        ),
      },
    ],
  },
]);
