import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../components/utils";

export const useWebflowBlogs = () => {
  return useQuery(["webflowBlogs"], () => {
    return makeApiRequest({
      endpoint: "/api/webflow",
      method: "GET",
      json: true,
    });
  });
};

export const useWebflowBlogById = (id) => {
  return useQuery(
    ["webflowBlogById", { id }],
    () => {
      return makeApiRequest({
        endpoint: `/api/webflow/${id}`,
        method: "GET",
        json: true,
      });
    },
    {
      enabled: !!id,
    }
  );
};
