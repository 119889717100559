import { useMutation } from "@tanstack/react-query";
import { makeApiRequest } from "components/utils";

export const useVendorEmailsUpload = () => {
  return useMutation(async ({ emailsCSV }) => {
    return makeApiRequest({
      endpoint: "/api/vendors/vendor-emails",
      method: "POST",
      body: JSON.stringify({ emailsCSV }),
      additionalHeaders: {
        "Content-Type": "application/json",
      },
    });
  });
};
